.typeform {
  label {
    font-family: "Mukta", sans-serif;
    line-height: 1.2em;
    font-size: 14px;
    color: #000000;
  }

  .result-table-section {
    width: 100%;
    height: 500px;
    overflow: auto;
    border: 1px solid #e5e5e5;
  }

  .table-row {
    display: flex;
    background-color: #fcfcfc;
  }
  .table-td {
    flex: 1 0 210px;
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    position: relative;
    line-height: 1.2em;
  }
  .table-row .table-td:last-child {
    border-right: none;
  }
  .table-header {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 3;
  }
  .table-header .table-td:first-child {
    position: sticky;
    left: 0;
    background-color: #ffffff;
    z-index: 3;
  }
  .table-header .table-td {
    vertical-align: middle;
    display: flex;
    align-items: center;
    background-color: #ffffff;
  }
  .date-picker-item {
    width: auto;
  }
  .date-picker-control {
    width: auto;
    border: 1px solid #e5e5e5;
    padding: 6px 15px;
    font-family: "Mukta", sans-serif;
    line-height: normal;
  }
  .table-header-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 180px;
    padding-right: 20px;
    font-size: 14px;
  }
  .table-header-title span {
    width: 28px;
    height: 28px;
    border-radius: 5px;
    background-color: #e9e9e9;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .table-header-title label {
    width: calc(100% - 28px);
    padding-left: 10px;
    line-height: 1.2em;
  }
  .table-header-title span img {
    max-width: 16px;
    max-height: 16px;
  }
  .table-dropdown {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
  }
  .three-dot-btn {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
    padding: 0 5px;
  }
  .three-dot-btn img {
    height: 15px;
  }
  .table-dropdown-box {
    position: absolute;
    padding: 10px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    right: 0;
    top: 100%;
    width: 200px;
    display: none;
  }

  .table-row .table-td {
    align-items: center;
  }

  .first-col {
    position: sticky;
    left: 0;
    background-color: #f9f9f9;
    z-index: 1;
  }
  .header.first-col {
    z-index: 2; /* Ensure it's above both header and first column */
  }

  .no-data-msg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
  }
  .red-tag {
    background-color: #ffb2b2;
    color: #000;
  }
  .data-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 11;
  }
  .data-loader img {
    width: 120px;
  }

  .table-pagination {
    width: 100%;
    margin: 30px 0;
    text-align: center;
  }
  .table-pagination ul {
    padding: 0;
    margin: 0;
    display: inline-flex;
  }
  .table-pagination ul li {
    display: inline-block;
    padding: 0 5px;
  }
  .table-pagination ul li a {
    display: inline-flex;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #eeeef7;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.5s;
  }
  .table-pagination ul li a.is-active {
    background-color: #0000ac;
    color: #ffffff;
  }
  .table-pagination ul li.nav-btn img {
    width: 8px;
    transition: all 0.2s;
  }
  .table-pagination ul li a:hover {
    background-color: #0000ac;
    color: #ffffff;
  }
  .table-pagination ul li a:hover img {
    filter: brightness(0) invert(1);
  }
  .blue-bg span {
    background-color: #379cfb;
  }
  .yellow-bg span {
    background-color: #fbce37;
  }
  .pink-bg span {
    background-color: #d65c99;
  }
}
