* {
  box-sizing: border-box;
}
html,
body {
  font-family: "Mukta", sans-serif;
  font-size: 16px;
  color: #000000;
  padding: 0;
  margin: 0;
}

textarea {
  font-family: "Mukta", sans-serif;
}
.container {
  width: 1140px;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.element-with-scroll,
.custom-filter-dropdown,
.vendor-detail-right ul {
  scrollbar-color: #d6d6e5 #f1f1f1;
  scrollbar-width: thin;
}
.element-with-scroll::-webkit-scrollbar,
.custom-filter-dropdown::-webkit-scrollbar,
.vendor-detail-right ul::-webkit-scrollbar {
  width: 5px;
}
.element-with-scroll::-webkit-scrollbar-thumb,
.custom-filter-dropdown::-webkit-scrollbar-thumb,
.vendor-detail-right ul::-webkit-scrollbar-thumb {
  background-color: red;
}

.element-with-scroll::-webkit-scrollbar-track,
.custom-filter-dropdown::-webkit-scrollbar-track,
.vendor-detail-right ul::-webkit-scrollbar-track {
  background-color: green;
}

.skeleton {
  background-color: #e2e5e7;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 101%;
  z-index: 1;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
@-webkit-keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

section.middle-block {
  width: 100%;
  background-color: #ffffff;
}

.vendor-list-block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f5f5;
  padding-left: 300px;
  position: relative;
  min-height: calc(100vh - 80px);
}
.vendor-listing-block {
  width: 300px;
  padding: 25px 30px;
  bottom: 0;
  background-color: #e8e8f2;
  -webkit-box-shadow: 2px 0px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0px 15px 0px rgba(0, 0, 0, 0.1);
  overflow: auto;
}
.vendor-listing-block h4 {
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 17px;
}
.categories-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.vendor-list {
  width: 100%;
  padding: 0;
  margin: 0;
}
.vendor-list li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
  position: relative;
}
.vendor-list li a {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  color: #000000;
  text-decoration: none;
}
.vendor-list li a:hover {
  color: #0000ac;
}
.list-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}
.vendor-detail-block {
  width: 100%;
  padding: 35px;
}
.vendor-detail-row {
  display: flex;
  flex-wrap: wrap;
}
.vendor-detail-col {
  width: 100%;
  padding: 0 0px 30px;
}
.vendor-detail-box {
  width: 100%;
  background-color: #ffffff;
  position: relative;
  border-radius: 12px;
  -webkit-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.09);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.vendor-detail-left {
  width: 62%;
  padding: 30px;
  padding-bottom: 80px;
}
.vendor-detail-right {
  width: 38%;
  padding: 30px;
  border-left: 1px solid #e1e1e1;
  position: relative;
}
.keywords-block {
  width: 100%;
  position: relative;
}
.vendor-top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.vendor-top-left {
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.vendor-top-left img {
  max-height: 80px;
}
.vendor-top-right {
  width: calc(100% - 100px);
  margin-left: 20px;
  position: relative;
  padding-right: 60px;
}
.vendor-top-right h2 {
  font-size: 22px;
  margin: 0 0 2px;
  color: #0000ac;
  line-height: 1.2em;
}
.vendor-top-right .verified-icon {
  display: inline-flex;
  padding-left: 10px;
  vertical-align: -2px;
}
.vendor-top-right .verified-icon img {
  width: 18px;
}
.vendor-top-right a {
  color: #000000;
}
.vendor-top-right a:hover {
  color: #0000ac;
}
.link-with-icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
}
.link-with-icon a {
  display: inline-flex;
  position: relative;
  padding: 1px 10px 1px 0px;
  text-decoration: none;
  word-break: break-all;
}
.link-with-icon span {
  position: static;
  left: 0;
  top: 6px;
  display: inline-flex;
}
.link-with-icon img {
  height: 16px;
}
.card-views {
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  line-height: normal;
}
.card-views span {
  padding-right: 6px;
}
.card-views span img {
  width: 22px;
}
.card-views a {
  padding: 0;
}
.tooltiptext {
  visibility: hidden;
  width: 132px;
  justify-content: center;
  text-align: center;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 5px;
  position: absolute !important;
  z-index: 1;
  top: -46px !important;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
  left: 52% !important;
  transform: translateX(-50%);
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.vendor-desc {
  margin-top: 15px;
  position: relative;
}
.vendor-desc p {
  margin: 5px 0 15px;
}
.vendor-other-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 15px 0;
  position: relative;
}
.vendor-other-details li {
  width: 33.33%;
  list-style: none;
  font-size: 15px;
  padding: 1px 3px 1px 0px;
  color: #454545;
}
.vendor-other-details li strong {
  color: #0000ac;
}
.vendor-linkedin {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
}
.vendor-linkedin img {
  width: 20px;
}
.vendor-filter-form {
  width: 100%;
  margin: 0 0 35px;
}
.vf-form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.vf-form-row > div {
  padding: 0 10px 10px;
}
.vf-form-col {
  width: 33.33%;
}
.vf-form-col label {
  width: 100%;
  display: block;
  margin: 0 0 3px;
}
.vf-form-col input {
  width: 100%;
}
.vf-form-btn {
  width: 100%;
  margin: 15px 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.vf-form-btn .clear-filter {
  margin-left: 27px;
}
.clear-filter {
  width: auto;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
  text-decoration: none;
}
.clear-filter img {
  width: 24px;
  margin-right: 6px;
}
.more-link {
  font-weight: 600;
  color: #0000ac;
  cursor: pointer;
}
.vendor-detail-right h2 {
  font-size: 22px;
  margin: 0 0 15px;
  color: #0000ac;
  line-height: 1.2em;
}
.vendor-detail-right ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  max-height: 145px;
  overflow: auto;
}
.vendor-detail-right ul li {
  display: inline-flex;
  align-items: center;
  padding: 3px 8px;
  color: #000000;
  background-color: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 100px;
  text-transform: capitalize;
  margin: 0 7px 7px 0px;
  font-size: 15px;
}
.stick-btn-block {
  position: absolute;
  bottom: 30px;
}

.custom-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1.5rem;
  width: 400px;
  max-width: 100%;
  border-radius: 10px;
}
.close-button {
  width: 1.5rem;
  cursor: pointer;
  position: absolute;
  right: 1.1rem;
  top: 0.7rem;
  display: inline-flex;
}
.close-button img {
  width: 30px;
}
.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
.modal-title {
  width: 100%;
  padding-right: 50px;
  margin-bottom: 15px;
}
.modal-title h2 {
  margin: 0;
  color: #0000ac;
  font-weight: 700;
}
.looking-for-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.looking-for-form .form-group {
  width: auto;
  padding: 0 20px 10px 0px;
}
.looking-for-form .btn {
  margin-top: 10px;
}
.custom-checkbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 12px 10px 0;
}
.custom-checkbox .checkbox {
  --bg: #fff;
  --brdr: #e8e8f2;
  --brdr-actv: #0000ac;
  --brdr-hovr: #e8e8f2;
  --dur: calc((var(--size, 2) / 2) * 0.6s);
  display: inline-block;
  width: calc(var(--size, 1) * 22px);
  position: relative;
}
.custom-checkbox label {
  margin: 0 0 0 6px;
  display: inline-block;
  line-height: 1em;
}
.custom-checkbox .checkbox:after {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}
.custom-checkbox .checkbox > * {
  position: absolute;
}
.custom-checkbox .checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  background-color: var(--bg);
  border-radius: calc(var(--size, 1) * 4px);
  border: calc(var(--newBrdr, var(--size, 1)) * 1px) solid;
  color: var(--newBrdrClr, var(--brdr));
  outline: none;
  margin: 0;
  padding: 0;
  transition: all calc(var(--dur) / 3) linear;
}
.custom-checkbox .checkbox input:hover,
.custom-checkbox .checkbox input:checked {
  --newBrdr: calc(var(--size, 1) * 2);
}
.custom-checkbox .checkbox input:hover {
  --newBrdrClr: var(--brdr-hovr);
}
.custom-checkbox .checkbox input:checked {
  --newBrdrClr: var(--brdr-actv);
  transition-delay: calc(var(--dur) / 1.3);
}
.custom-checkbox .checkbox input:checked + svg {
  --dashArray: 16 93;
  --dashOffset: 109;
}
.custom-checkbox .checkbox svg {
  fill: none;
  left: 0;
  pointer-events: none;
  stroke: var(--stroke, var(--border-active));
  stroke-dasharray: var(--dashArray, 93);
  stroke-dashoffset: var(--dashOffset, 94);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  top: 0;
  transition: stroke-dasharray var(--dur), stroke-dashoffset var(--dur);
}
.custom-checkbox .checkbox svg,
.custom-checkbox .checkbox input {
  display: block;
  height: 100%;
  width: 100%;
}
.no-data-found {
  width: 100%;
  padding: 10px;
  border: 1px solid #999999;
  border-radius: 10px;
  text-align: center;
}
.no-data-found h2 {
  margin: 10px 0px;
}
.lf-block {
  width: 100%;
  padding-bottom: 15px;
  position: relative;
  padding: 20px;
  border: 1px solid #d5d5d5;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-top: 25px;
}
.lf-block h4 {
  margin: 0 0 10px;
  font-size: 20px;
}
.custom-filter {
  width: auto;
  position: relative;
}
.popup-btn-block {
  width: 100%;
}
.custom-filter .custom-filter-button,
.custom-filter select {
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  padding: 12px 15px;
  border-radius: 7px;
  color: #000000;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-image: url("../svgs/angle_down_icon.svg");
  background-position: 97% 50%;
  background-size: 14px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.custom-filter .custom-filter-dropdown {
  position: absolute;
  top: 90%;
  background-color: #e8e8f2;
  left: 0px;
  right: 0px;
  padding: 20px;
  border: 1px solid #e1e1e1;
  display: none;
  max-height: 250px;
  overflow: auto;
  z-index: 1;
}
.custom-filter .form-group {
  margin: 0 0 10px;
  padding-left: 30px;
  position: relative;
  width: 100%;
}
.custom-filter .form-group input[type="checkbox"],
.custom-filter .form-group input[type="radio"] {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
}
.custom-filter .form-group label {
  vertical-align: middle;
  line-height: 1.5em;
}
.row-selection {
  width: auto;
  display: flex;
  align-items: center;
}
.row-selection .custom-filter-button {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 7px;
  padding: 10px;
  color: #fff;
  width: 70px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  background-image: url("../svgs/angle_down_icon.svg");
  background-position: 96% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.row-selection .custom-filter-button option {
  background-color: #040b30;
}
.row-selection .custom-filter-dropdown {
  padding: 0;
  width: 100%;
  left: 0;
}
.row-selection .custom-filter-dropdown button {
  width: 100%;
  display: block;
  padding: 7px;
  font-size: 14px;
  text-align: left;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}
.row-selection .custom-filter-dropdown button:hover {
  background-color: #3800f5;
  color: #fff;
}
.row-selection label {
  font-size: 14px;
  margin-left: 10px;
}

.filter-btn {
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.filter-btn .btn {
  display: inline-flex;
  align-items: center;
}
.filter-btn .btn img {
  display: inline-flex;
  width: 20px;
  margin-right: 7px;
  transition: all 0.5s;
}
.filter-btn .btn:hover img {
  img {
    filter: brightness(0) invert(0);
  }
}
.filter-btn .clear-filter {
  margin-left: 15px;
}

/* FORM */

.col-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}
.col-row > div {
  padding: 0 20px;
}
.col-3 {
  width: 25%;
  padding: 0 20px;
}
.col-4 {
  width: 33.33%;
  padding: 0 20px;
}
.col-8 {
  width: 66.66%;
  padding: 0 20px;
}
.col-6 {
  width: 50%;
  padding: 0 20px;
}
.col-12 {
  width: 100%;
  padding: 0 20px;
}
.form-control {
  padding: 10px 15px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 7px;
  width: 100%;
  height: 45px;
}
.btn {
  display: inline-block;
  padding: 10px 28px;
  background: #0000ac;
  color: #fff;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
  line-height: 1.4em;
}
.btn:hover {
  background-color: #2e80ec;
}

.form--section {
  width: 100%;
  padding: 50px 0px;
}
.form-grp label {
  width: 100%;
  display: block;
  margin: 0 0 5px;
}
.form-grp {
  width: 100%;
  position: relative;
}
.form--section .col-row > div {
  padding-bottom: 18px;
}
textarea.form-control {
  resize: none;
  height: 100px;
}
select.form-control {
  background-image: url("../svgs/angle_down_icon.svg");
  background-position: 97% 50%;
  background-size: 14px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.prefix {
  position: absolute;
  top: 40px;
  left: 15px;
  font-weight: 600;
}
.prefix + .form-control {
  padding-left: 30px;
}
.custom-file-upload-hidden {
  display: none;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}
.custom-file-upload {
  display: block;
  width: auto;
  font-size: 16px;
  margin-top: 30px;
}
.custom-file-upload label {
  display: block;
  margin-bottom: 5px;
}

input[type="file"].form-control {
  max-width: 100%;
  padding: 5px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  height: auto;
}
input[type="file"].form-control::file-selector-button {
  display: inline-block;
  padding: 9px 15px;
  color: #000000;
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
  background: #d8e2ee;
  margin-right: 10px;
}
.file-upload-options {
  width: auto;
  display: inline-flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  margin: 5px 0px 15px;
  border-radius: 8px;
  padding: 5px;
}
.file-upload-options button {
  border: 0;
  padding: 10px 15px;
  color: #000000;
  min-width: 100px;
  background-color: transparent;
  border-radius: 8px;
  cursor: pointer;
}
.file-upload-options button.active {
  background-color: #2e80ec;
  color: #ffffff;
}
.col-12 .form-grp select {
  background-position: 98.6% 50%;
}
.msg-note {
  font-size: 13px;
  color: #666666;
  line-height: normal;
  display: inline-block;
  width: 100%;
}
.form-error input,
.form-error select,
.form-error .custom-filter-button,
.form-error textarea,
.form-error input[type="file"].form-control {
  border-color: #ff2121;
}
.form-error .msg-note {
  color: #ff2121;
}
.fieldset-block {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
}
.error-info-msg {
  width: 100%;
  margin: 0 0 30px;
  border: 1px solid #ff2121;
  padding: 12px 20px;
  border-radius: 6px;
  color: #ff2121;
  background-color: rgba(255, 33, 33, 0.06);
}
.preview-button-block {
  width: 100%;
  margin: 0 0 30px;
  display: flex;
  justify-content: flex-end;
}
.preview-button-block .btn,
.preview-button-block button {
  margin-left: 10px;
}
.u-f-block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0 0;
}
.u-f-block h2 {
  width: 100%;
}
.u-f-box {
  width: 100%;
  display: flex;
  align-items: center;
}
.u-f-box > a {
  display: inline-flex;
}
.u-f-box > a img {
  max-width: 28px;
  max-height: 36px;
}
.u-f-icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  text-decoration: none;
  margin: 0 0 15px;
}
.u-f-icon label {
  width: calc(100% - 28px);
  padding-left: 15px;
  color: #000;
  line-height: 1.3em;
  cursor: pointer;
  transition: all 0.3s;
}
.u-f-icon:hover label {
  color: #2e80ec;
}
.u-f-video-preview {
  width: auto;
  width: 100px;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.u-f-video-preview::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}
.u-f-video-preview img {
  height: 15px;
}

.image-upload-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.avatar-upload {
  position: relative;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 162px;
  height: 46px;
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  background-color: #2e80ec;
  color: #ffffff;
  border-radius: 8px;
  text-align: center;
}
.avatar-upload .avatar-edit input + label:hover {
  border-color: #d6d6d6;
  background-color: #0000ac;
}
.avatar-upload .avatar-edit input + label span {
  display: inline-flex;
  padding-right: 12px;
}
.avatar-upload .avatar-edit input + label span img {
  width: 20px;
}
.avatar-upload .avatar-preview {
  width: calc(100% - 180px);
  height: 70px;
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.avatar-preview-img {
  height: 80px;
  width: auto;
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.avatar-upload .avatar-preview .upload-text {
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  line-height: 1.3em;
  border-radius: 0;
  background: none;
  color: #666666;
}
.loader-block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.8);
}
.loader-block img {
  width: 100px;
}

.f-vh-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.thank-you-block {
  width: 768px;
  max-width: 100%;
  margin: 0 auto;
  padding: 50px;
  -webkit-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  text-align: center;
}
.thank-you-img {
  max-height: 300px;
}
.thank-you-block h2 {
  font-size: 28px;
}

/* FORM */

/* VENOBOX POPUP */

.vbox-inline {
  padding: 40px;
}
.vendor-filter-title h2 {
  margin-top: 0;
}
.vf-cnd-col {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.vf-cnd-col select,
.vf-cnd-col input {
  margin-right: 20px;
}
.form-control-1 {
  width: calc(14% - 5px);
  background-position: 93% 50% !important;
}
.form-control-2 {
  width: calc(24% - 5px);
}
.form-control-3 {
  width: calc(18% - 5px);
}
.form-control-4 {
  width: calc(30% - 5px);
}
.remove-col,
.add-filter {
  width: 33px;
  padding: 0;
  border: 0;
  background: transparent;
  display: inline-block;
  padding: 5px;
  cursor: pointer;
}

/* VENOBOX POPUP */

.full-width {
  width: 100% !important;
}
.tag {
  width: auto;
  display: inline-flex;
  background-color: #efebff;
  color: #0000ac;
  line-height: 1.2em;
  padding: 2px 7px;
  border-radius: 50px;
  margin: 4px 0 4px 4px;
}
.interested-in {
  width: 100%;
}
.interested-in h2 {
  color: #0000ac;
  margin-bottom: 10px;
}
.interested-in-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.interested-in-col {
  padding: 5px;
  width: 50%;
}
.interested-in-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 15px;
  background-color: #f7f6f9;
  border: 1px solid #f1f1f1;
}
.interested-in-box span {
  display: inline-flex;
  width: 30px;
}
.interested-in-box span img {
  max-height: 30px;
}
.interested-in-box h5 {
  margin: 0;
  width: calc(100% - 30px);
  padding-left: 15px;
  line-height: 1.3em;
  margin: 0;
}

.radio-box {
  width: auto;
  position: relative;
}
.radio-box [type="radio"]:checked,
.radio-box [type="radio"]:not(:checked) {
  position: absolute;
  left: 0;
  z-index: 1;
  border: 0;
  right: 0;
  bottom: 0;
  display: block;
  opacity: 0;
  margin: 0;
  height: 100%;
  cursor: pointer;
}
.radio-box [type="radio"]:checked + span,
.radio-box [type="radio"]:not(:checked) + span {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.radio-box [type="radio"]:checked + span:before,
.radio-box [type="radio"]:not(:checked) + span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.radio-box [type="radio"]:checked + span:after,
.radio-box [type="radio"]:not(:checked) + span:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #0000ac;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.radio-box [type="radio"]:not(:checked) + span:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.radio-box [type="radio"]:checked + span:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.vbox-container {
  overflow-y: auto;
}
.vbox-close {
  background-color: #ffffff;
  opacity: 1;
  color: #000000;
  padding: 10px;
}
.cn-form-q {
  width: 100%;
  margin: 0 0 12px;
}
.cn-form-q h4 {
  margin: 0 0 2px;
  font-size: 18px;
}
.radio-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.radio-wrapper > div {
  padding: 0 10px;
}
.radio-box {
  width: auto;
}
.cn-submit-btn {
  width: 100%;
  margin: 25px 0 0;
}
.other-q {
  width: 100%;
  margin: 10px 0 0;
}
.other-q textarea {
  height: 60px;
  display: block;
}
.required-error .error.radio-box [type="radio"]:not(:checked) + span {
  color: #c00000;
}
.required-error .form-control.error {
  border-color: #c00000;
}
.error-note {
  font-size: 12px;
  color: #c00000;
  display: block;
}

@media only screen and (max-width: 1199px) {
  .vf-form-col {
    width: 50%;
  }
  .vendor-detail-left {
    width: 100%;
    padding: 25px;
  }
  .vendor-detail-right {
    width: 100%;
    border: 0px;
    border-top: 1px solid #e5e5e5;
    padding: 25px;
  }
  .vendor-other-details li {
    width: 50%;
  }
  .vendor-listing-block {
    padding: 20px;
    width: 270px;
  }
  .vendor-listing-block h4 {
    font-size: 22px;
  }
  .vendor-list li {
    font-size: 15px;
  }
  .vendor-list-block {
    padding-left: 270px;
  }
}

@media only screen and (max-width: 1080px) {
  .vf-cnd-col select,
  .vf-cnd-col input {
    margin-right: 18px;
  }
}
@media only screen and (max-width: 1023px) {
  .vf-cnd-col select,
  .vf-cnd-col input {
    margin-right: 14px;
  }
  .form-control-4 {
    width: calc(27% - 5px);
  }
}
@media only screen and (max-width: 767px) {
  .vbox-inline {
    padding: 25px;
  }
  .vf-cnd-col select,
  .vf-cnd-col input {
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .form-control-1 {
    width: calc(50% - 12px);
  }
  .form-control-2 {
    width: calc(50% - 12px);
  }
  .form-control-3 {
    width: calc(50% - 12px);
  }
  .form-control-4 {
    width: calc(50% - 12px);
  }
  .link-with-icon {
    position: static;
  }
}
@media only screen and (max-width: 639px) {
  .vf-form-col {
    width: 100%;
  }
}
