.table-header {
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #070639;
}

.table-row td {
    border-bottom: 1px solid #F2F3F8;
}

.table-cell {
    font-weight: 500 !important;
    line-height: 21px !important;
    font-size: 13px !important;
    color: #6C7293 !important;
}